import { useQuery, ApolloClient, useMutation } from "@apollo/client"
import React from "react"
import { RouteChildrenProps } from "react-router"
import { AdminSQLMaintPoolsQuery, AdminSQLMaintPoolsQueryVariables } from "../../../__generated__/AdminSQLMaintPoolsQuery"
import Layout from "../components/Layout"
import LoadingView from "../components/LoadingView"
import { ADMIN_MAINT_POOLS_QUERY, ADMIN_SQL_QUERY, ADMIN_RESTORE_ENTRY_MUTATION } from "../queries"
import DataTable, { IDescribeResult, IDataTableColFunctionalities } from "../Database/DataTable"
import { fromGlobalId } from "../../../common/global-ids"
import { DateTime } from "luxon"
import InfoCard from "./InfoCard"
import Add from "@material-ui/icons/Add"
import CloseIcon from "@material-ui/icons/Close"
import { AdminRestoreEntry, AdminRestoreEntryVariables } from "../../../__generated__/AdminRestoreEntry"
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core"

export function PoolForm({ pool, props }) {
  const { id, name } = pool

  const descriptionColumn: IDescribeResult = {
    Field: "description",
    Type: "json",
    Null: "YES",
    Key: "",
    Default: null,
    Extra: "",
    tableName: "Activity",
  }

  const dataTableColFunctionalities: IDataTableColFunctionalities = {
    goToId: false,
    highlightColJson: true,
    jsonColButton: true,
    searchCol: true,
  }
  const [restoreEntryMutation, { loading: mutationLoading }] = useMutation<AdminRestoreEntry, AdminRestoreEntryVariables>(
    ADMIN_RESTORE_ENTRY_MUTATION,
  )
  const [snackMessage, setSnackMessage] = React.useState("")

  const onSnackClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === "clickaway") {
      return
    }
    setSnackMessage("")
  }

  return (
    <>
      <InfoCard maintenanceTitle={`Pool ID: ${id}`} maintenanceSubtitle={`Pool Name: ${name}`} />

      <br />

      {mutationLoading ? (
        <LoadingView variant="view" />
      ) : (
        <DataTable
          {...props}
          poolId={id}
          isDeletable={false}
          isEditable={false}
          additionalActions={[
            (rowData) => {
              if (rowData.verb === "REMOVED" && !rowData.extra.revertedAt) {
                return {
                  icon: Add,
                  tooltip: `Restore removed entry and all picks`,
                  onClick: async (_e, rowData1) => {
                    const result = await restoreEntryMutation({
                      variables: { entryData: rowData1.extra.Entry, activityDbId: rowData1.id },
                    })

                    const messages = (result.errors && JSON.stringify(result.errors)) || result.data?.restoreEntry || [`Success!`]
                    setSnackMessage(messages[0] + ` (${messages[1]})`)
                  },
                }
              } else {
                return null
              }
            },
          ]}
          title={`${name} Activities`}
          tableNameFromProp="Activity"
          whitelistedColumns={[
            "id",
            "actor",
            "actorTableName",
            "actorTableId",
            "object",
            "objectTableName",
            "objectTableId",
            "verb",
            "target",
            "targetTableName",
            "targetTableId",
            "time",
            "createdAt",
            "updatedAt",
            "extra",
            "description",
          ]}
          excludeLayout={true}
          sqlWhereScopes={[
            `(actorTableName = "Pool" AND actorTableId = ${fromGlobalId(id).id} OR (objectTableName = "Pool" AND objectTableId = ${
              fromGlobalId(id).id
            }) OR (targetTableName = "Pool" AND targetTableId = ${
              fromGlobalId(id).id
            }) OR (objectTableName = "Entry" AND objectTableId IN (SELECT id FROM Entry WHERE Entry.poolId = ${
              fromGlobalId(id).id
            })) OR (targetTableName = "Entry" AND targetTableId IN (SELECT id FROM Entry WHERE Entry.poolId = ${fromGlobalId(id).id})))`,
          ]}
          virtualColumns={[descriptionColumn]}
          modifyResults={modifySQLresults}
          dataTableColFunctionalities={dataTableColFunctionalities}
        />
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={!!snackMessage}
        autoHideDuration={null}
        onClose={onSnackClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#558b2f",
          }}
          message={snackMessage}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={onSnackClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </>
  )
}

export const modifySQLresults = async (sqlRows: any, client: ApolloClient<any>) => {
  const modifiedRows = JSON.parse(JSON.stringify(sqlRows.data.sql))
  const responseWithVirtualColumns = await Promise.all(
    modifiedRows.map(async (element) => {
      const description = await getDescriptionFor(element, client)
      return { ...element, description: description }
    }),
  )
  return responseWithVirtualColumns
}

const createDt = (date: Date | string) => {
  const millis = date instanceof Date ? date.getTime() : Date.parse(date)
  return DateTime.fromMillis(millis)
}

const sqlQuery = async (statement: string, client: ApolloClient<any>, ids?: string[]) => {
  const response = await client.query({
    query: ADMIN_SQL_QUERY,
    variables: { statement, ids },
  })
  return response.data.sql as any[]
}

export const getRow = async (tableName: string, tableId: number, client: ApolloClient<any>) => {
  const result = await sqlQuery(
    `
  SELECT *
  FROM ${tableName}
  WHERE id = ${tableId};`,
    client,
  ).catch((err) => {
    throw err
  })
  if (result.length === 0) {
    throw new Error(`No results found`)
  }
  return result
}
export const getNameFor = (row: any | null | string) =>
  (!row && " ") || (typeof row === "string" && row) || row.displayName || row.preferredEntryName || `unknown row type: ${JSON.stringify(row)}`

const getRowData = async (tableName, tableId, extra, client) => {
  let rowData = {} as any
  try {
    rowData = await getRow(tableName, tableId, client)
  } catch (err) {
    if (extra && extra.hasOwnProperty(tableName)) {
      rowData = extra[`${tableName}`]
    }
    if (!rowData) {
      throw err
    }
  }
  return rowData
}

const getDescriptionFor = async (element, client) => {
  const {
    actor,
    object,
    target,
    actorTableName,
    actorTableId,
    objectTableName,
    objectTableId,
    targetTableId,
    targetTableName,
    verb,
    time,
    extra,
  } = element

  const actorRow = actor !== "unknown" && (await getRowData(actorTableName, actorTableId, extra, client))
  const targetRow = target && (await getRowData(targetTableName, targetTableId, extra, client))
  const objectRow = object && (await getRowData(objectTableName, objectTableId, extra, client))

  const actions = [] as any[]
  const settingsArray = [] as any[]
  let description
  let hasPeriodId = false
  let periodId

  if (verb === "UPDATED") {
    Object.keys(extra).forEach((key) => {
      if (key === "poolSettings") {
        const poolSettings = JSON.parse(extra[key])
        if (poolSettings != null) {
          Object.keys(poolSettings).forEach((settings) => {
            if (settings != null && settings != null) {
              settingsArray.push(`${settings} to '${poolSettings[settings]}'`)
            }
          })
        }
      }
      if (key === "periodId") {
        hasPeriodId = true
        periodId = extra[key]
      }
      const settingsString = settingsArray.join(" and ")
      actions.push(`${verb.toLowerCase()} ${key} ${settingsString ? `: ${settingsString} ` : `to '${extra[key]}'`}`)
    })

    const actionString = hasPeriodId ? `Set custom events for pool period: ${periodId} ` : actions.join(" and ")
    description = `${actorTableName}:${getNameFor(actorRow[0])} ${verb} ${objectTableName}:${getNameFor(objectRow[0])} on ${createDt(
      time,
    ).toLocaleString(DateTime.DATETIME_MED)}. More details: ${actionString}`
  }
  if (verb === "EDITED") {
    description = `${actorTableName}:${getNameFor(actorRow[0])} ${verb} ${objectTableName}:${getNameFor(objectRow[0])} on ${createDt(
      time,
    ).toLocaleString(
      DateTime.DATETIME_MED,
    )}. More details: This user edited their picks. See the extra field for what pick was edited. For more info on that, contact the Picks Platform Developers.`
  }

  if (verb === "REMOVED" || verb === "ADDED") {
    const entryName = objectRow.length && (objectRow.displayName || objectRow[0].displayName)
    const entryId = objectRow.length && (objectRow.id || objectRow[0].id)
    const entryEmail = objectRow.length && (objectRow.email || objectRow[0].email)

    description = `${actorTableName}:${getNameFor(actorRow[0])} ${verb} ${objectTableName}:${entryName} ${
      verb === "ADDED" ? "to" : "from"
    } ${targetTableName}:${getNameFor(targetRow[0])} on ${createDt(time).toLocaleString(
      DateTime.DATETIME_MED,
    )}. More details: ${objectTableName}: '${entryName}', id:'${entryId}', email:'${entryEmail}'`
  }

  return description
}

function MaintPoolPage1(props: RouteChildrenProps<any>) {
  const poolId = props.match?.params.poolId
  const poolsQuery = useQuery<AdminSQLMaintPoolsQuery, AdminSQLMaintPoolsQueryVariables>(ADMIN_MAINT_POOLS_QUERY, { variables: { poolId } })

  return (
    <Layout title={`Maintenance - Pool: ` + poolsQuery.data?.pool.name}>
      {(poolsQuery.data?.pool && <PoolForm pool={poolsQuery.data.pool} props={props} />) || <LoadingView />}
    </Layout>
  )
}
export default MaintPoolPage1
